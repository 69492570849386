import { fetchData, postRequest, putRequest } from './index';

function getUserDetail() {
  return fetchData('user/detail');
}

function getUserData() {
  return fetchData('user');
}

function getPotentialMatches(page = 0) {
  return fetchData(`user/potential-matches?page=${page}`);
}

function getMyMatches(isChat = false) {
  return fetchData(`user/my-matches?isChat=${isChat}`);
}

function updateUserDetails(userId, detailsToUpdate) {
  return putRequest(`user/${userId}`, detailsToUpdate);
}

function updateProfilePhotos(profilePhotos) {
  return putRequest('user/update-profile-photos', profilePhotos);
}

function setProfilePhoto(profilePhoto) {
  return putRequest('user/set-profile-photo', profilePhoto);
}

function getUserSettings() {
  return fetchData('user/settings');
}

function updateUserSettings(profilePhotos) {
  return putRequest('user/settings', profilePhotos);
}

function submitContactQuery(detailsToSend) {
  return postRequest('common/submit-contact', detailsToSend);
}

function getOccupations() {
  return fetchData('common/get-occupations');
}

function getCommonMasterData() {
  return fetchData('common/get-master-data');
}

function createOrder(detailsToSend) {
  return postRequest('order/create', detailsToSend);
}

function createOrderForGift(detailsToSend) {
  return postRequest('order/createGift', detailsToSend);
}

function updateOrder(details) {
  return postRequest('order/update', details);
}

function updateOrderForGift(details) {
  return postRequest('order/updateGift', details);
}

function getOrderDetails() {
  return fetchData('order');
}

function getRoseHistory() {
  return fetchData('order/roseHistory');
}

function getLikeHistory() {
  return fetchData('order/likeHistory');
}

function getLastOrder() {
  return fetchData('order/getlastOrder');
}

function getPlans() {
  return fetchData('plan');
}

function getRosePlans() {
  return fetchData('plan/rosePlans');
}

function getLikePlans() {
  return fetchData('plan/likesPlans');
}

function createPlans(details) {
  return postRequest('plan', details);
}

function getUpgradePlan() {
  return fetchData('plan/getUpgradePlan');
}

function userLike(details) {
  return postRequest('user/mactchAction', details);
}

function getMatchCount() {
  return fetchData('user/getMatchCount');
}

function getUserPlans() {
  return fetchData('user/getUserPlans');
}

function getConversations(details) {
  return postRequest('user/getConversations', details);
}

function sendMessage(details) {
  return postRequest('user/sendMessage', details);
}

function getUserDetails(userId) {
  return fetchData(`user/${userId}`);
}

function changePassword(details) {
  return postRequest('user/changePassword', details);
}

function changeEmail(details) {
  return postRequest('user/changeEmail', details);
}

function addEmail(details) {
  return postRequest('user/addEmail', details);
}

function otpVerifyForAddEmail(details) {
  return postRequest('user/otpVerifyForAddEmail', details);
}

function cancelService(details) {
  return postRequest('user/cancelService', details);
}

function conversationBadgeReset(details) {
  return postRequest('user/conversationBadgeReset', details);
}

function chatDelete(id) {
  return postRequest('user/chatDelete', { chatId: id });
}

function getMyRoses() {
  return fetchData('user/myRoses');
}

function getMasterData() {
  return postRequest('user/getMasterDataList', {});
}

function userChatActions(details) {
  return postRequest('user/userChatActions', details);
}

// admin's
function getDashboardSummary() {
  return fetchData('admin/getDashboardSummary');
}

function getUserList() {
  return fetchData('admin/getUserList');
}

function getContactUserList() {
  return fetchData('admin/getContactUserList');
}

function getUserAddedDataList() {
  return fetchData('admin/getUserAddedDataList');
}

function updateUserAddedData(id, detailsToUpdate) {
  return putRequest(`admin/updateUserAddedData/${id}`, detailsToUpdate);
}

function changeUserStatus(id, details) {
  return putRequest(`admin/changeUserStatus/${id}`, details);
}

function changeActivityStatus(id, details) {
  return putRequest(`admin/changeActivityStatus/${id}`, details);
}

function changeContactStatus(id, details) {
  return putRequest(`admin/changeContactStatus/${id}`, details);
}

function giftPaymentRetry(details) {
  return postRequest('order/paymentRetry', details);
}

function changeRecipient(details) {
  return postRequest('order/changeRecipient', details);
}

function getCountryCulture() {
  return fetchData('auth/get-country-culture');
}

function createOrderByStripe(detailsToSend) {
  return postRequest('order/createStripeOrder', detailsToSend);
}

function createFeedbacks(detailsToSend) {
  return postRequest('user/userFeedbacks', detailsToSend);
}

function createOrderNew(detailsToSend) {
  return postRequest('order/createNewOrder', detailsToSend);
}

function captureOrder(detailsToSend) {
  return postRequest('order/captureOrder', detailsToSend);
}

function updateUserPremiumProfileStatus(id, details) {
  return putRequest(`admin/users/premium-profile/change/${id}`, details);
}

function updateUserPremiumProfileOrder(details) {
  return putRequest(`admin/users/premium-profile/change/order`, details);
}

function createAuthorizeNetOrder(details) {
  return postRequest(`order/authorize-net/create`, details);
}

function cancelAuthorizeNetSubscription() {
  return fetchData(`order/authorize-net/subscription/cancel`);
}

function requestProfileDataUpdate(details) {
  return putRequest(`admin/user-profile/request-data-update`, details);
}

function getHomeConfigData(isoCode = '') {
  return fetchData(`common/home/config/${isoCode}`);
}

function updateMatchQueueCountryPreference(details) {
  return putRequest(`user/match-queue/country-filter/update`, details);
}

function sendOtpToUserStudentEmail(params) {
  return postRequest('user/verify-student-email/send-otp', params);
}

function verifyOtpForUserStudentEmail(params) {
  return postRequest('user/verify-student-email/verify-otp', params);
}


const webServices = {
  createOrderNew,
  captureOrder,
  getUserDetail,
  getPotentialMatches,
  getMyMatches,
  updateUserDetails,
  updateProfilePhotos,
  setProfilePhoto,
  getUserSettings,
  updateUserSettings,
  submitContactQuery,
  getOccupations,
  getCommonMasterData,
  createOrder,
  createOrderForGift,
  updateOrder,
  updateOrderForGift,
  getPlans,
  getRosePlans,
  createPlans,
  getOrderDetails,
  getRoseHistory,
  getLikeHistory,
  getUpgradePlan,
  getLastOrder,
  getUserData,
  userLike,
  getMatchCount,
  getUserPlans,
  getConversations,
  sendMessage,
  getUserDetails,
  changePassword,
  changeEmail,
  addEmail,
  otpVerifyForAddEmail,
  cancelService,
  conversationBadgeReset,
  chatDelete,
  getMyRoses,
  getMasterData,
  getDashboardSummary,
  getUserList,
  getUserAddedDataList,
  updateUserAddedData,
  changeUserStatus,
  userChatActions,
  getLikePlans,
  giftPaymentRetry,
  changeRecipient,
  getContactUserList,
  changeContactStatus,
  getCountryCulture,
  createOrderByStripe,
  changeActivityStatus,
  createFeedbacks,
  updateUserPremiumProfileStatus,
  updateUserPremiumProfileOrder,
  createAuthorizeNetOrder,
  cancelAuthorizeNetSubscription,
  requestProfileDataUpdate,
  getHomeConfigData,
  updateMatchQueueCountryPreference,
  sendOtpToUserStudentEmail,
  verifyOtpForUserStudentEmail
};

export default webServices;
