import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../redux/actions/user.actions';
import webServices from '../../services/webServices';
import { pageTitle } from '../../redux/actions/home.actions';
import Spinner from '../general/spinner';
import offer from '../../assets/images/Offer.svg';
import PlanMoreInfoModalComponent from './PlanMoreInfoModalComponent';
import AuthorizeNetPayment from '../../services/AuthorizeNetPayment';
import VerifyStudentEmailPopup from './VerifyStudentEmailPopup';

const GetPayNowBtnComponent = ({ subscribedPlanId, isStudentEmailVerified, plan, handelStudentPlanBtnClick, studentPayBtnRef }) => {
  const { id: planId, price: planPrice } = plan;

  if (subscribedPlanId === null && (planId === 9 || (planId === 10 && isStudentEmailVerified === 1))) return (<AuthorizeNetPayment
    planId={planId}
    planAmount={planPrice.toFixed(2)}
    planType='SUBSCRIPTION_PLAN'
    selectedPlan={plan}
    isHidden={0}
    btnRef={planId === 10 ? studentPayBtnRef : null}
  />)

  if (subscribedPlanId === null && planId === 10) return (
    <>
      <button type="button" className="btn btn-info theme-btn ml-3 r_color mb-2 mb-md-0" onClick={handelStudentPlanBtnClick}>
        Pay Now
      </button>
      <AuthorizeNetPayment
        planId={planId}
        planAmount={planPrice.toFixed(2)}
        planType='SUBSCRIPTION_PLAN'
        selectedPlan={plan}
        isHidden={1}
        btnRef={studentPayBtnRef}
      />
    </>
  )

  return <></>;
};

const PlansComponent = () => {
  const bottomRef = useRef();
  const studentPayBtnRef = useRef();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const { current } = useSelector((state) => state.user);

  const [pageData, setPageData] = useState({
    isSpinner: true,
    showPlanMoreInfoModal: false,
    isSubscriptionPurchased: null,
    isStudentEmailVerified: 0,
    isStudentVerifyModalVisible: false,
  });

  const getPlanPrice = plan => plan.price.toFixed(2);

  const displayPlanPrice = (plan) => {
    const planPrice = getPlanPrice(plan).split('.');
    return `$${planPrice[0]}.<sup>${planPrice[1]}</sup>`;
  };

  useEffect(async () => {
    dispatch(pageTitle('Plans'));

    const resp = await webServices.getUserDetail();
    if (resp && resp.success === true) {
      setPageData((prev) => ({
        ...prev,
        isSubscriptionPurchased: resp.data.plan_subscription_id !== null ? resp.data.currentPlanId : null,
        isStudentEmailVerified: resp.data.is_student_email_verified
      }));

      dispatch(setUser(resp.data));
    }

    (async () => {
      const response = await webServices.getPlans();
      if (response && response.success) {
        setPlans(response.data);
        setTimeout(() => {
          setPageData((prev) => ({ ...prev, isSpinner: false }));
        }, 400);
      }
    })();
  }, []);

  useEffect(() => {
    setPageData((prev) => ({
      ...prev,
      isSubscriptionPurchased: current.plan_subscription_id !== null ? current.currentPlanId : null,
      isStudentEmailVerified: current.is_student_email_verified
    }));
  }, [current]);


  const handelPlanMoreInfoPopupCloseBtn = () => setPageData((prev) => ({ ...prev, showPlanMoreInfoModal: false }));
  const handelPlanMoreInfoPopupShowIcon = () => setPageData((prev) => ({ ...prev, showPlanMoreInfoModal: true }));

  const handelStudentVerifyPopupCloseBtn = () => setPageData((prev) => ({ ...prev, isStudentVerifyModalVisible: false }));
  const handelStudentPlanBtnClick = () => setPageData((prev) => ({ ...prev, isStudentVerifyModalVisible: true }));

  const handelStudentEmailVerifiedBtn = () => {
    if (studentPayBtnRef.current) {
      studentPayBtnRef.current.click();
      handelStudentVerifyPopupCloseBtn();
    }
  };

  return (
    <>
      <div className="full-page planWrapper">
        <div className="container">
          {pageData.isSpinner ? (
            <Spinner active={pageData.isSpinner} />
          ) : (
            <>
              <h1 className="text-center text-lg-center text-md-center">
                Simple, Transparent Pricing
              </h1>

              {!pageData.isSubscriptionPurchased && (
                <div className="animates_wrapper">
                  <div className="sale glowWrap">
                    {' '}
                    <img
                      src={offer}
                      className="img-rose-fluid"
                      alt="Lovrican rose"
                    />{' '}
                    <span className="">
                      <span className="sun_font">I Love Africa </span>2025  Sale
                    </span>
                    <img
                      src={offer}
                      className="img-rose-fluid"
                      alt="Lovrican rose"
                    />
                  </div>
                </div>
              )}

              <p className="sale_details">
                We keep our pricing simple. You only pay $9.<sup>99</sup> per month. Students get 50% off.

                <span className='lear_more'>
                  Learn more
                  <span className='dot_wrapper ml-2' href='/' onClick={handelPlanMoreInfoPopupShowIcon} onKeyDown={() => { }} role='presentation'>
                    <span className='dot' />
                    <span className='dot' />
                    <span className='dot' />
                  </span>
                </span>
              </p>

              <div className="mid-container-outer" ref={bottomRef}>
                <div className="panConatiner">
                  <div className="row student_standPlan_wrapper">
                    {plans.length && plans.map(plan => (
                      <div className={`box ${plan.id === 10 ? 'student' : 'standard'} ${pageData.isSubscriptionPurchased !== null && pageData.isSubscriptionPurchased !== plan.id ? 'd-none' : ''}`}>
                        <p className='offer'><span>{plan.id === 10 ? '$4.99 (50% off)' : '&nbsp;'}</span></p>
                        <h2>{plan.name}</h2>
                        <div className="plan-details">
                          <div className="nav flex-column nav-pills">
                            <a
                              className="nav-link active"
                              data-toggle="pill"
                              href="/"
                              key={plan.id}
                            >
                              <div className="d-flex justify-content-between align-items-start flex-wrap flex-md-nowrap pan_wrap">
                                <div className="lft d-flex align-items-center">
                                  <div className="icon mr-3">
                                    <i className="fa fa-circle-thin d-none" aria-hidden="true" />
                                    <i className="fa fa-check-circle d-none" aria-hidden="true" />
                                  </div>
                                  <div className="plan_title">
                                    <h3>Lovrican Subscription</h3>
                                  </div>
                                </div>
                                <div className="mr-3">{
                                  pageData.isSubscriptionPurchased === plan.id ? (
                                    <div className='subPlan'><i className="fa fa-check mr-2" aria-hidden="true" />Subscribed</div>
                                  ) : ''
                                }</div>
                                <div className="rgt">
                                  {/* eslint-disable-next-line react/no-danger */}
                                  <div className="price" dangerouslySetInnerHTML={{ __html: displayPlanPrice(plan) }} />
                                  <div className='month mb-2'>per month</div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className="white-border" />
                          <div className='pay_wrapper'>
                            <div className="mobile_plan justify-content-center text-center">
                              {GetPayNowBtnComponent({ subscribedPlanId: pageData.isSubscriptionPurchased, isStudentEmailVerified: pageData.isStudentEmailVerified, plan, handelStudentPlanBtnClick, studentPayBtnRef })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <PlanMoreInfoModalComponent show={pageData.showPlanMoreInfoModal} handelClose={handelPlanMoreInfoPopupCloseBtn} />

      <VerifyStudentEmailPopup isModalVisible={pageData.isStudentVerifyModalVisible} handelClose={handelStudentVerifyPopupCloseBtn} handelEmailVerified={handelStudentEmailVerifiedBtn} />
    </>
  );
};

export default PlansComponent;
