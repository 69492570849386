import { React, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import SimpleReactValidator from 'simple-react-validator';
import webServices from '../../services/webServices';
import verifiedIcon from '../../assets/images/check.svg';
import { setUser } from '../../redux/actions/user.actions';

// Step 1
const VerifyEmailComponent = (props) => {
  const { changeFormStepHandler } = props;
  const [formData, setFormData] = useState({
    email: '',
    isBtnDisabled: false
  });
  const validator = useRef(new SimpleReactValidator({
    validators: {
      eduEmail: {
        message: 'The email must end with .edu',
        rule: (val) => val.endsWith('.edu'),
        required: true,
      },
    }
  }));
  const [, forceUpdate] = useState();

  const handelEmailOnChangeEvent = (evt) => {
    setFormData((prev) => ({ ...prev, email: evt.target.value }));
    validator.current.showMessageFor('email');
  };

  const sendOtpToEmailBtn = async () => {
    if (validator.current.allValid()) {
      setFormData((prev) => ({ ...prev, isBtnDisabled: true }));

      // Trying to send otp to email
      const result = await webServices.sendOtpToUserStudentEmail({ email: formData.email, isResend: 0 });
      setFormData((prev) => ({ ...prev, isBtnDisabled: false }));
      if (result && result.success === true) {
        changeFormStepHandler();
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  }

  return (
    <>
      Verify your college email (must end in .edu) to take advantage of this offer

      <input type="text" id='email' name='email' value={formData.email} onChange={handelEmailOnChangeEvent} className="form-control" placeholder="Enter your college email" />
      {validator.current.message('email', formData.email, 'required|email|eduEmail', { className: 'text-danger' })}

      <div className="text-center">
        <button type="button" className="btn btn-info theme-btn ml-3 r_color mb-2 mb-md-0" onClick={sendOtpToEmailBtn} disabled={formData.isBtnDisabled}>
          Verify Email
        </button>
      </div>
    </>
  );
};

// Step 2
const VerifyOtpComponent = (props) => {
  const otpLength = 4;
  const { changeFormStepHandler } = props;
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const [otp, setOtp] = useState('');
  const [formData, setFormData] = useState({
    isHelpVisible: false,
    isBtnDisabled: false
  });

  const handelResendOtpBtn = async () => {
    // Trying to re-send otp to email
    setFormData((prev) => ({ ...prev, isBtnDisabled: true }));
    const result = await webServices.sendOtpToUserStudentEmail({ email: formData.email, isResend: 1 });
    setFormData((prev) => ({ ...prev, isBtnDisabled: false }));
    if (result && result.success === true) {
      toast.success("OTP has been send to your mentioned college email.");
    }
  }

  const handelVerifyOtpBtn = async () => {
    // Trying to verify otp
    setFormData((prev) => ({ ...prev, isBtnDisabled: true }));
    const result = await webServices.verifyOtpForUserStudentEmail({ otp });
    setFormData((prev) => ({ ...prev, isBtnDisabled: false }));
    if (result && result.success === true) {
      dispatch(setUser({ ...current, is_student_email_verified: 1 }));
      changeFormStepHandler();
    }
  }

  return (
    <>
      Please enter the OTP we just sent to your college email to verify it.

      <div className="row w-100 settingOtp">
        <div className="col-12 d-flex align-items-end justify-content-center">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={otpLength}
            containerStyle="userOtpBbox"
            inputStyle="inputStyleNew"
            shouldAutoFocus
            className="inputStyleNew focus-color otpInput"
            isInputNum
          />

          <div className="position-relative">
            <div
              className="helpTrip"
              aria-hidden="true"
              onClick={() => setFormData((prev) => ({ ...prev, isHelpVisible: true }))}
            >
              <i className="fa fa-question" aria-hidden="true" />
            </div>
            {formData.isHelpVisible && (
              <div className="toolTipHelp d-flex">
                <p>Check your college email for the One Time Passcode. Use this code to verify you email</p>
                <div className="ml-3">
                  <i
                    className="fa fa-times cursor-pointer"
                    aria-hidden="true"
                    onClick={() => setFormData((prev) => ({ ...prev, isHelpVisible: false }))}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='settingOtp_des'>
        <small>Enter the OTP we just sent to your college email.</small>
      </div>

      <div className='d-flex resend-wrapper'>
        <span> Didn&apos;t get the code? </span>
        <button type="button" className="btn btn-info" onClick={handelResendOtpBtn} disabled={formData.isBtnDisabled}>Resend Code</button>
      </div>
      <div className='text-center very_otp'>
        <button type="button" className="btn btn-info theme-btn ml-3 r_color mb-2 mb-md-0 mt-0" onClick={handelVerifyOtpBtn} disabled={formData.isBtnDisabled}>
          Verify OTP
        </button>
      </div>
    </>
  );
};

// Step 3
const EmailVerifiedComponent = (props) => {
  const { handelEmailVerified } = props;

  useEffect(() => {
    setTimeout(handelEmailVerified, 2000);
  }, []);

  return (
    <div className='email-verified'>
      <div className='icon text-center mt-4'>
        <img src={verifiedIcon} className="img-rose-fluid" alt="Lovrican rose" />
      </div>
      <div className='head'>College email verified</div>
      <p>Congratulations! Your college email has been verified. Redirecting to payment page.</p>
    </div>
  )
};

const VerifyStudentEmailPopup = (props) => {
  const { isModalVisible, handelClose, handelEmailVerified } = props;
  const [formStep, setFormStep] = useState(1);

  const changeFormStepHandler = () => setFormStep((prev) => prev + 1);

  // Reset form to 1st step
  useEffect(() => {
    if (isModalVisible) {
      setFormStep(1);
    }
  }, [isModalVisible]);


  return (
    <>
      <Modal
        className="filter-model PlanStatusModel paymentModel studentPlanModal"
        show={isModalVisible}
        onHide={handelClose}
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100">STUDENT PLAN</h5>
            <button type="button" className="close" data-dismiss="modal" onClick={handelClose}>&times;</button>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="loader-payment0">
          {
            // eslint-disable-next-line no-nested-ternary
            formStep === 1 ?
              <VerifyEmailComponent changeFormStepHandler={changeFormStepHandler} /> :
              (
                formStep === 2 ?
                  <VerifyOtpComponent changeFormStepHandler={changeFormStepHandler} /> :
                  <EmailVerifiedComponent handelEmailVerified={handelEmailVerified} />
              )

          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerifyStudentEmailPopup;
