/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable react/no-did-update-set-state */
import { Country, State, City } from 'country-state-city';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-dropdown-select';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
import KidsStatus from '../../appConstants/kidsStatus';
import AppConstants from '../../appConstants';
import logo from '../../assets/images/Logo.svg';
import { datediff } from '../../helpers/validator';
import { authCall } from '../../services';
// import webServices from '../../services/webServices';
// import arrowIcon from '../../assets/images/arrow-circle.svg'

const KIDS = [
  {
    value: 1,
    label: 1
  },
  {
    value: 2,
    label: 2
  },
  {
    value: 3,
    label: 3
  },
  {
    value: 4,
    label: 4
  },
  {
    value: 5,
    label: 5
  },
  {
    value: 6,
    label: 6
  },
  {
    value: 7,
    label: 7
  },
  {
    value: 8,
    label: 8
  },
  {
    value: 9,
    label: 9
  },
  {
    value: 10,
    label: 10
  }
];

class Section2RegisterComponent extends React.Component {
  constructor(props) {
    super(props);
    const { userDetails } = props;
    this.state = {
      aboutMe: userDetails?.about ? userDetails?.about : '',
      hobbies: userDetails?.hobbies ? userDetails?.hobbies : [],
      culture: userDetails?.userDetails?.cultures ? [userDetails?.userDetails?.cultures] : [],
      subCulture: userDetails?.userDetails?.languages ? [userDetails?.userDetails?.languages] : [],
      valueData: userDetails?.values_col ? userDetails?.values_col : [],
      kidStatus: userDetails?.userDetails?.children ? [userDetails?.userDetails?.children] : [],
      u_country: '',
      u_state: userDetails?.userDetails?.residence2 ? [userDetails?.userDetails?.residence2] : [],
      u_city: userDetails?.userDetails?.residence3 ? [userDetails?.userDetails?.residence3] : [],
      countries: [],
      states: [],
      cities: [],
      request: true,
      u_country_origin: userDetails?.userDetails?.origin1 ? [userDetails?.userDetails?.origin1] : [],
      u_state_origin: userDetails?.userDetails?.origin2 ? [userDetails?.userDetails?.origin2] : [],
      u_city_origin: userDetails?.userDetails?.origin3 ? [userDetails?.userDetails?.origin3] : [],
      countriesOrigin: [],
      statesOrigin: [],
      citiesOrigin: [],
      totalKids: '',
      selectedCountry: JSON.parse(localStorage.getItem('selectedCountry'))
    };

    this.validator = new SimpleReactValidator({
      validators: {
        custom_date: {
          message: 'Your age must be greater then 18.',
          rule: (val = new Date()) => {
            const valLocal = val || new Date();
            const curDate = new Date();
            const diff = datediff(valLocal, curDate);
            return valLocal.getTime() <= curDate.getTime() && diff > 365 * 18;
          },
          required: true
        },
        max_five: {
          message: 'Maximum of 5 values allowed.',
          rule: (val) => val.length < 6,
          required: true
        }
      }
    });
  }

  async componentDidMount() {
    this.getCountriesOptions();
    const { selectedCountry } = this.state;
    if (selectedCountry) {
      const data = await State.getStatesOfCountry(selectedCountry[0].value);
      const options = data.map((d) => ({
        value: d.isoCode,
        label: d.name
      }));
      this.setState({
        u_country: selectedCountry[0],
        // u_state: '',
        // u_city: '',
        states: options,
        cities: []
      });
    }
  }

  getCountriesOptions = async () => {
    const data = await Country.getAllCountries();
    const options = data.map((d) => ({
      value: d.isoCode,
      label: `${d.flag} ${d.name} (${d.isoCode})`
    }));
    this.setState({ countries: options, countriesOrigin: options });
  };

  handelCountryChange = async (value) => {
    if (value[0]) {
      // console.log(value[0]);
      const data = await State.getStatesOfCountry(value[0].value);
      const options = data.map((d) => ({
        value: d.isoCode,
        label: d.name
      }));
      this.setState({
        u_country: value[0],
        u_state: [],
        u_city: [],
        states: options,
        cities: []
      });
    } else {
      this.setState({
        u_country: [],
        u_state: [],
        u_city: [],
        states: [],
        cities: []
      });
    }
  };

  handelStateChange = async (value) => {
    if (value[0]) {
      const { u_country: uCountry } = this.state;
      const data = await City.getCitiesOfState(uCountry.value, value[0].value);
      const options = data.map((d) => ({
        value: d.name,
        label: d.name
      }));
      this.setState({
        u_state: value[0],
        u_city: [],
        cities: options
      });
    } else {
      this.setState({
        u_state: [],
        u_city: [],
        cities: []
      });
    }
  };

  handelCityChange = async (value) => {
    if (value[0]) {
      this.setState({ u_city: value[0] });
    } else {
      this.setState({
        u_city: []
      });
    }
  };

  handelCountryChangeOrigin = async (value) => {
    if (value[0]) {
      const data = await State.getStatesOfCountry(value[0].value);
      const options = data.map((d) => ({
        value: d.isoCode,
        label: d.name
      }));
      this.setState({
        u_country_origin: value[0],
        u_state_origin: [],
        u_city_origin: [],
        statesOrigin: options,
        citiesOrigin: []
      });
    } else {
      this.setState({
        u_country_origin: [],
        u_state_origin: [],
        u_city_origin: [],
        statesOrigin: [],
        citiesOrigin: []
      });
    }
  };

  handelStateChangeOrigin = async (value) => {
    if (value[0]) {
      const { u_country_origin: uCountry } = this.state;
      const data = await City.getCitiesOfState(uCountry.value, value[0].value);
      const options = data.map((d) => ({
        value: d.name,
        label: d.name
      }));
      this.setState({
        u_state_origin: value[0],
        u_city_origin: [],
        citiesOrigin: options
      });
    } else {
      this.setState({
        u_state_origin: [],
        u_city_origin: [],
        citiesOrigin: []
      });
    }
  };

  handelCityChangeOrigin = async (value) => {
    if (value[0]) {
      this.setState({ u_city_origin: value[0] });
    } else {
      this.setState({
        u_city_origin: []
      });
    }
  };

  submitForm = async () => {
    const {
      aboutMe,
      hobbies,
      culture,
      subCulture,
      valueData,
      kidStatus,
      totalKids,
      request,
      u_country: uCountry,
      u_state: uState,
      u_city: uCity,
      u_country_origin: uCountryOrigin,
      u_state_origin: uStateOrigin,
      u_city_origin: uCityOrigin
    } = this.state;
    const { dispatch, history } = this.props; // goToNextStep
    if (this.validator.allValid() && request) {
      this.setState({ request: false });
      const formData = {
        aboutMe,
        hobbies,
        culture,
        subCulture: Array.isArray(subCulture) ? subCulture[0] : subCulture,
        valueData,
        kidStatus,
        totalKids,
        country: uCountry,
        state: uState,
        city: uCity,
        countryOrigin: Array.isArray(uCountryOrigin) ? uCountryOrigin[0] : uCountryOrigin,
        stateOrigin: Array.isArray(uStateOrigin) ? uStateOrigin[0] : uStateOrigin,
        cityOrigin: Array.isArray(uCityOrigin) ? uCityOrigin[0] : uCityOrigin,
        profileLevel: 0, // userDetails?.profileLevel === 7 ? 0 : 5
        is_gift_mail_sent: 1
      };

      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        // if (resp.isSentWelcomeMail) {
        //   goToNextStep({ step: 6 });
        //   setTimeout(() => {
        //     history.push(AppConstants.routes.afterLogin);
        //   }, 4000);
        // } else {
        //   history.push(AppConstants.routes.afterLogin);
        // }
        history.push(AppConstants.routes.afterLogin);
      }

      this.setState({ request: true });
    } else {
      window.scrollTo(0, 0);
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleClick = (val) => {
    this.setState({ kidStatus: val });
  };

  handleSkip = async () => {
    const { history } = this.props;
    // const resp = await fetchData('auth/skip');
    // if (resp.isSentWelcomeMail) {
    //   goToNextStep({ step: 6 });
    //   setTimeout(() => {
    //     history.push(AppConstants.routes.afterLogin);
    //   }, 4000);
    // } else {
    //   history.push(AppConstants.routes.afterLogin);
    // }
    history.push(AppConstants.routes.afterLogin);
  };

  render() {
    const {
      aboutMe,
      hobbies,
      culture,
      subCulture,
      valueData,
      kidStatus,
      totalKids,
      u_country: uCountry,
      countries,
      states,
      u_state: uState,
      u_city: uCity,
      cities,
      u_country_origin: uCountryOrigin,
      countriesOrigin,
      statesOrigin,
      u_state_origin: uStateOrigin,
      u_city_origin: uCityOrigin,
      citiesOrigin,
      request,
      selectedCountry
    } = this.state;
    const { masterData } = this.props;
    return (
      <>
      {/* {console.log(JSON.stringify(this.state))} */}
        <section className="registerDetails">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center">
                <div className="logo cursor-pointer" role="button" tabIndex="0">
                  <img src={logo} className="img-fluid" alt="Lovrican Logo" />
                </div>
              </div>
            </div>

            <div className="row join-reg pt-4 mt-4 mb-4">
              <div className="col-md-12 col-lg-12">
                <h1>
                  Basic Profile
                  <br />
                  Section 2: Intro to Matches
                </h1>
              </div>
            </div>
            <div className="row join-reg bottom-join pb-0">
              <div className="col-md-6 col-lg-6 pb-2 mb-3">
                <textarea
                  type="text"
                  name="aboutMe"
                  placeholder="About Me"
                  className="custom_input resize-none"
                  autoComplete="off"
                  value={aboutMe}
                  onChange={(e) => this.setState({ aboutMe: e.target.value })}
                />
                {this.validator.message(
                  'aboutMe',
                  aboutMe,
                  'required|min:50|max:300'
                )}
              </div>
              <div className="col-md-6 col-lg-6 pb-2 custome_select mb-3 ">
                <Select
                  placeholder="Hobbies (Max 5)"
                  multi
                  options={masterData?.hobbies}
                  limit={5}
                  autoComplete="off"
                  className="custom_input"
                  values={hobbies}
                  onChange={(value) => this.setState({ hobbies: value })}
                />
                {this.validator.message(
                  'hobbies',
                  hobbies,
                  'required|max_five'
                )}
              </div>
              <div className="col-md-6 col-lg-3 pb-2 mb-3 mt-lg-2">
                <div className="form-lebel"> Residence:</div>
              </div>
              <div className="col-md-6 col-lg-3 pb-2 mb-3 custome_select mt-lg-2">
                <Select
                  placeholder="Country Name"
                  options={countries}
                  autoComplete="off"
                  className="custom_input"
                  values={selectedCountry}
                  onChange={(value) => this.handelCountryChange(value)}
                />
                {this.validator.message('country', uCountry, 'required')}
              </div>
              <div className="col-md-6 col-lg-3 pb-2 mb-3 custome_select mt-lg-2">
                <Select
                  placeholder="State Name"
                  options={states}
                  autoComplete="off"
                  className="custom_input"
                  values={uState}
                  onChange={(value) => this.handelStateChange(value)}
                />
                {this.validator.message('state', uState, 'required')}
              </div>
              <div className="col-md-6 col-lg-3 pb-4 mb-1 mt-lg-2 custome_select">
                <Select
                  placeholder="City Name"
                  autoComplete="off"
                  options={cities}
                  className="custom_input"
                  values={uCity}
                  onChange={(value) => this.handelCityChange(value)}
                />
                {this.validator.message('city', uCity, 'required')}
              </div>
              <div className="col-md-6 col-lg-3 pb-2 mb-3 mt-lg-2">
                <div className="form-lebel"> Origin:</div>
              </div>
              <div className="col-md-6 col-lg-3 pb-2 mb-3 custome_select mt-lg-2">
                <Select
                  placeholder="Country Name"
                  options={countriesOrigin}
                  autoComplete="off"
                  className="custom_input"
                  values={uCountryOrigin}
                  onChange={(value) => this.handelCountryChangeOrigin(value)}
                />
                {this.validator.message('country', uCountryOrigin, 'required')}
              </div>
              <div className="col-md-6 col-lg-3 pb-2 mb-3 custome_select mt-lg-2">
                <Select
                  placeholder="State Name"
                  options={statesOrigin}
                  autoComplete="off"
                  className="custom_input"
                  values={uStateOrigin}
                  onChange={(value) => this.handelStateChangeOrigin(value)}
                />
                {this.validator.message('state', uStateOrigin, 'required')}
              </div>
              <div className="col-md-6 col-lg-3 pb-4 mb-1 mt-lg-2 custome_select">
                <Select
                  placeholder="City Name"
                  autoComplete="off"
                  options={citiesOrigin}
                  className="custom_input"
                  values={uCityOrigin}
                  onChange={(value) => this.handelCityChangeOrigin(value)}
                />
                {this.validator.message('city', uCityOrigin, 'required')}
              </div>

              <div className="col-md-6 col-lg-3 pb-2 mb-3 custome_select mt-lg-2">
                <Select
                  placeholder="Culture"
                  options={masterData?.cultrual}
                  autoComplete="off"
                  className="custom_input"
                  values={culture}
                  onChange={(value) => this.setState({ culture: value[0] })}
                />
                {this.validator.message('culture', culture, 'required')}
              </div>
              <div className="col-md-6 col-lg-3 pb-2 mb-3 custome_select mt-lg-2">
                <Select
                  placeholder="Sub-Culture"
                  options={masterData?.languages}
                  autoComplete="off"
                  className="custom_input"
                  values={subCulture}
                  onChange={(value) => this.setState({ subCulture: value })}
                />

                {this.validator.message('subCulture', subCulture, 'required')}
              </div>
              <div className="col-md-6 col-lg-3 pb-2 mb-3 custome_select mt-lg-2">
                <Select
                  multi
                  placeholder="Values (Max 5)"
                  options={masterData?.valuesDetails}
                  limit={5}
                  autoComplete="off"
                  className="custom_input"
                  values={valueData}
                  onChange={(value) => this.setState({ valueData: value })}
                />
                {this.validator.message(
                  'valueData',
                  valueData,
                  'required|max_five'
                )}
              </div>
            </div>
            <div className="row join-reg bottom-join join-btn-select pb-2">
              <div className="col-md-6 col-lg-3 pb-2 mb-3 mt-lg-2">
                <div className="form-lebel"> Children:</div>
              </div>
              {/* <div className="col-md-6 col-lg-6 pb-4 mb-3 "> */}
              <div className="col-md-6 col-lg-3 pb-2 mb-3 custome_select mt-lg-2">
                <Select
                  placeholder="Status"
                  options={KidsStatus}
                  autoComplete="off"
                  className="custom_input"
                  values={kidStatus}
                  onChange={(value) => this.setState({ kidStatus: value[0] })}
                />
                {this.validator.message('kidStatus', kidStatus, 'required')}
                {/* <div className="card-container">
                  <ul className="list-unstyled list1 mb-0">
                    {KidsStatus.map((valueInner) => (
                      <li
                        key={valueInner.label}
                        onClick={this.handleClick(valueInner)}
                        aria-hidden="true"
                        style={{
                          cursor: 'pointer',
                          backgroundColor:
                            valueInner.label === kidStatus?.label
                              ? '#B800E0'
                              : '#E4DCE6'
                        }}
                      >
                        {valueInner && valueInner.label}
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>

              <div className="col-md-6 col-lg-3 pb-2 mb-3 custome_select mt-lg-2">
                {kidStatus?.value > 3 && (
                  <>
                    <Select
                      placeholder="How Many Kids"
                      options={KIDS}
                      autoComplete="off"
                      className="custom_input"
                      onChange={(value) => {
                        // console.log(value[0]);
                        this.setState({ totalKids: value[0] });
                      }}
                    />
                    {this.validator.message('totalKids', totalKids, 'required')}
                  </>
                )}
              </div>
              <div className="ccol-md-6 col-lg-8 pb-4 mb-3 join-links marb7 text-right">
                &nbsp;
              </div>
              <div className="ccol-md-6 col-lg-4 pb-4 mb-3 join-links marb7 text-right">
                <div className="w-100 d-flex justify-content-end align-items-center">
                  <span
                    onClick={this.handleSkip}
                    onKeyDown={(event) =>
                      event.key === 'Enter' && this.handleSkip()
                    }
                    role="button"
                    tabIndex="0"
                    className="new_skip_btn"
                  >
                    SKIP
                  </span>

                  <span
                    className={`new_next_btn ${
                      !request ? 'hks-btn-disable' : ''
                    }`}
                    onClick={this.submitForm}
                    onKeyDown={(event) =>
                      event.key === 'Enter' && this.submitForm()
                    }
                    role="button"
                    tabIndex="0"
                  >
                    {/* <img src={arrowIcon} className="img-fluid" alt="arrow icon" />a */}
                    Meet Matches
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default connect()(Section2RegisterComponent);
