import React, { useEffect, useState } from 'react';
import { Country } from 'country-state-city';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../redux/actions/user.actions';
import webServices from '../../../services/webServices';
import RenderFormElement from '../../editable-sections/RenderFormElement';

const LifeStyleEditForm = ({ userDetail, masterData, viewMode }) => {
  const dispatch = useDispatch();
  const [formControls, setFormControls] = useState({});
  const [countryList, setCountryList] = useState({});

  const handleSaveDetails = async (values) =>
    webServices.updateUserDetails(userDetail.id, values).then((response) => {
      if (response && response.success) {
        dispatch(setUser(response.data));
      }
      return response;
    });

    const getCountriesOptions = async () => {
      const data = await Country.getAllCountries();
      const options = data.map((d) => ({
        value: d.isoCode,
        label: `${d.name}`
      }));
      setCountryList(options);
    };

  useEffect(() => {
    if (!userDetail) {
      return;
    }
    getCountriesOptions();
    setFormControls({
      descriptiveQualities: {
        label: 'Descriptive Qualities',
        type: 'special',
        options: [
          { value: '1', label: 'Loyal' },
          { value: '2', label: 'Caring' }
        ],
        value: userDetail.descriptiveQualities
      },
      movies: {
        label: 'Favorite Movies',
        type: 'dropdown',
        options: masterData.movies,
        value: userDetail?.userDetails?.movies || '',
        isCreatable: true,
        isMulti: true
      },
      places: {
        label: 'Favorite Places',
        type: 'dropdown',
        options: countryList,
        value: userDetail?.userDetails?.places || '',
        isCreatable: true,
        isMulti: true
      },
      foods: {
        label: 'Favorite Food',
        type: 'dropdown',
        options: masterData.foods,
        value: userDetail?.userDetails?.foods || '',
        isCreatable: true,
        isMulti: true
      },
      musicians: {
        label: 'Favorite Musicians',
        type: 'dropdown',
        options: masterData.musicians,
        value: userDetail?.userDetails?.musicians || '',
        isCreatable: true,
        isMulti: true
      },
      tvShows: {
        label: 'Favorite TV Shows',
        type: 'dropdown',
        options: masterData.tvShows,
        value: userDetail?.userDetails?.tvShows || '',
        isCreatable: true,
        isMulti: true
      },
      podcasts: {
        label: 'Favorite Podcasts',
        type: 'dropdown',
        options: masterData.podcasts,
        value: userDetail?.userDetails?.podcasts || '',
        isCreatable: true,
        isMulti: true
      },
      // streamingShows: {
      //   label: 'Favorite Streaming Shows',
      //   type: 'dropdown',
      //   options: masterData.streamingShows,
      //   value: userDetail?.userDetails?.streamingShows || '',
      //   isCreatable: true,
      //   isMulti: true
      // },
      sportsWatching: {
        label: 'Favorite Sports',
        type: 'dropdown',
        options: masterData.sports,
        value: userDetail?.userDetails?.sportsWatching || '',
        isCreatable: true,
        isMulti: true
      },
      // sportsPlaying: {
      //   label: 'Sport - Playing',
      //   type: 'dropdown',
      //   options: masterData.sports,
      //   value: userDetail?.userDetails?.sportsPlaying || '',
      //   isCreatable: true,
      //   isMulti: true
      // },
      influencedBy: {
        label: 'Influenced Most By',
        type: 'text',
        value: userDetail.userDetails?.influencedBy || '',
        placeholder: 'Write your answer',
        isTextArea: true
      },
      importantInRelationship: {
        label: 'Important in Relationship',
        type: 'text',
        value: userDetail.userDetails?.importantInRelationship || '',
        placeholder: 'Write your answer',
        isTextArea: true
      },
      idealDay: {
        label: 'Ideal Day',
        type: 'text',
        value: userDetail.userDetails?.idealDay || '',
        placeholder: 'Write your answer',
        isTextArea: true
      },
      makesMeLaugh: {
        label: 'What Makes Me Laugh',
        type: 'text',
        value: userDetail.userDetails?.makesMeLaugh || '',
        placeholder: 'Write your answer',
        isTextArea: true
      },
      cannotStand: {
        label: "Can’t Stand",
        type: 'text',
        value: userDetail.userDetails?.cannotStand || '',
        placeholder: 'Write your answer',
        isTextArea: true
      },
      canNotLiveWithout: {
        label: "Can’t Live Without",
        type: 'text',
        value: userDetail.userDetails?.canNotLiveWithout || '',
        placeholder: 'Write your answer',
        isTextArea: true
      },
      lifePurpose: {
        label: "My Life’s Purpose",
        type: 'text',
        value: userDetail.userDetails?.lifePurpose || '',
        placeholder: 'Write your answer',
        isTextArea: true
      }
    });
  }, [userDetail, masterData]);

  return (
    <>
      {Object.keys(formControls).map((formControlName) => (
        <RenderFormElement
          isViewMode = {viewMode}
          key={formControlName}
          formControlName={formControlName}
          {...formControls[formControlName]}
          saveDetails={handleSaveDetails}
        />
      ))}
    </>
  );
};

export default LifeStyleEditForm;
